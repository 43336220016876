.navContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar {
  position: fixed;
  top: 3vh;
  z-index: 999;

  height: 40px;
  width: 480px;
}

.navBackGround {
  border-radius: 40px;
  height: 100%;
  width: 100%;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.navList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 12px 18px;
}

.navLink {
  color: #27272a;
  text-decoration: none;
}

.navLink:hover {
  color: var(--primary-color); 
}

.navLinkActive {
  color: white;

  background-color: var(--primary-color); 
  text-decoration: none;

  padding: 8px 20px;
  border-radius: 40px;

  margin-left: -12px;
  margin-right: -12px;
}

.navItem {
  font-size: 80%;
  font-weight: 700;
}

.contentBackground {
  background-color: #f1f1f1;
}

.contentWrapper {
  background-color: white;

  max-width: 90%;

  margin-left: 8%;
  margin-right: 8%;

  padding-left: 3vw;
  padding-right: 3vw;

  padding-top: 150px;
  padding-bottom: 0px;
}

.footerWrapper {
  margin-left: 8%;
  margin-right: 8%;
  padding-left: 3%;
  padding-right: 3%;
  max-width: 90%;
  background-color: white;
  padding-top: 80px;
}

@media only screen and (max-width: 600px) {
  .navbar {
    width: 82%;
    top: 6vh;
  }

  .contentWrapper {
    max-width: 100%;

    margin-left: 0px;
    margin-right: 0px;

    padding-top: 120px;
  }

  .footerWrapper {
    margin-left: 0px;
    margin-right: 0px;
    max-width: 100%;
  }
}

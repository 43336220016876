.pageContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.logoWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 25%;

  justify-content: space-around;
}

.img {
  aspect-ratio: 19 / 20;
  object-fit: contain;
  width: 100%;
  height: auto;
  opacity: 1;
  border-radius: 12%;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.socialLinks {
  width: 100%;

  display: flex;
  flex-direction: row;

  justify-content: space-between;
  cursor: pointer;
}

a {
  color: #65656d;
}

.socialLinks svg:not(:hover) {
  transition: 1.4s;
  transform: rotate(-360deg);
}

.socialLinks svg:hover {
  color: var(--primary-color);
  transition: 1.4s;
  transform: rotate(360deg);
}

.cardContainer {
  max-width: 60%;
  min-width: 260px;

  opacity: 0.8;

  height: max-content;
  min-height: 180px;

  padding-top: 22px;
  padding-bottom: 16px;

  padding-left: 32px;
  padding-right: 32px;

  border-radius: 20px;
  outline: 2px solid #f4f4f5;

  display: flex;
  flex-direction: column;
}

.cardContainer:hover {
  background: #fafafa;
  opacity: 1;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}

.cardTitle {
  color: #27272a;
  font-weight: 650;

  display: flex;
  flex-direction: row;
  justify-self: flex-start;
  column-gap: 14px;
  align-items: center;
}

.cardContent {
  margin-top: 16px;
  font-size: 20px;
  line-height: 22px;

  color: #65656d;
}

.cardContent p {
  font-size: 16px;
}

.navLink {
  text-decoration: none;
  color: #65656d;
}

.navLinkHover {
  text-decoration: none;
  color: var(--primary-color);
}

.cardContentList {
  list-style: none;
  margin-bottom: 0px;
  padding-left: 0px;

  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.item1 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  color: #27272a;
}

.item10 {
  font-size: 16px;
  line-height: 18px;
}

.item3 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #65656d;
}

@media only screen and (max-width: 600px) {
  .logoWrapper {
    max-width: 90%;
    justify-content: space-between;
    padding: 10px;
  }

  .cardContainer {
    min-width: 90%;

    padding-top: 18px;
    padding-bottom: 12px;

    padding-left: 12px;
    padding-right: 12px;
  }

  .cardContent {
    font-size: 18px;
    line-height: 20px;
  }

  .item1 {
    font-size: 16px;
    line-height: 20px;
  }

  .item3 {
    font-size: 12px;
    line-height: 16px;
  }
}

.projectsTitle {
  color: #27272a;
  font-size: 45px;
  font-weight: 700;
  width: 80%;
}

.projectsSubTitle {
  color: #65656d;
  font-size: 16px;
  line-height: 28px;
  width: 80%;
  margin-top: 35px;
  margin-bottom: 35px;

  text-align: justify;
}

.projectsList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

@media only screen and (max-width: 991px) {
  .projectsTitle {
    width: 100%;
  }
  .projectsSubTitle {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .projectsTitle {
    font-size: 35px;
  }
  .projectsSubTitle {
    font-size: 14px;
  }
}

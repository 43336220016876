
.contactTitle {
  font-weight: 700;
  font-size: 45px;
  width: 80%;
  color: #27272a;
}

.contactSubTitle {
  color: #65656d;
  font-size: 16px;
  line-height: 28px;
  width: 80%;
  margin-top: 35px;

  text-align: justify;
}

.contactsListWrapper {
  padding-top: 35px;
  display: flex;
  justify-content: flex-start;
}

.contactList {
  display: flex;
  flex-direction: column;
}

.linkWrapper {
  display: flex;
  flex-direction: row;
}

.contactItem {
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #65656d;
  text-decoration: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 12px;
}

.contactItem:hover {
  color: var(--primary-color);
}

@media only screen and (max-width: 991px) {
  .contactTitle {
    width: 100%;
  }

  .contactSubTitle {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .contactTitle {
    font-size: 35px;
  }

  .contactSubTitle {
    font-size: 14px;
    line-height: 26px;
  }

  .contactItem {
    font-size: 12px;
  }
}

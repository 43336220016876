.profile {
  position: fixed;
  top: 3vh;
  right: 9%;
  transform: translate(-9%, 0);

  margin-left: 8%;
  margin-right: 8%;
}

@media only screen and (max-width: 600px) {
  .profile {
    position: fixed;
    top: 1vh;
    bottom: 2vh;
    width: max-content;

    left: 50%;
    transform: translate(-80%, 0);
  }
}

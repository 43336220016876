.editSkillContainer {
  padding-top: 80px;
  width: 100%;
}

.editCertificateContainer {
  padding-top: 80px;
  width: 100%;
}

.editEducationContainer {
  padding-top: 80px;
  width: 100%;
}

.editExperienceContainer {
  padding-top: 80px;
  width: 100%;
}

.editUserContainer {
  padding-top: 80px;
  width: 94%;
  margin-left: 3%;
  margin-right: 3%;
}

.editProjectContainer {
  padding-top: 80px;
  width: 100%;
}
